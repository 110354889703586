// import printMe from './about.js';
// import ScrollMagic from 'scrollmagic';
import Turbolinks from 'turbolinks';
// import Tippy from 'tippy.js';
import AOS from 'aos';
import SmoothScroll from 'smoothscroll-polyfill';
import Cookie from 'js-cookie';

(function() {
  // printMe();
  // console.log('index.js');

  // FUNCTIONS
  // var forEach = function (array, callback, scope) {
  //   for (var i = 0; i < array.length; i++) {
  //     callback.call(scope, i, array[i]); // passes back stuff we need
  //   }
  // };

  function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
      return window.matchMedia(query).matches;
    }
  
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      return true;
    }
  
    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }
  
  // INIT
  if (isTouchDevice()) {
    document.querySelector('body').classList.add('has-touch');
  }

  Turbolinks.start();
  
  SmoothScroll.polyfill();
  
  AOS.init({
    offset: 30,
    duration: 750,
    easing: 'ease',
    once: true,
  });

  document.addEventListener("turbolinks:load", function() {
    // console.log('here');
    if (document.querySelector('.content') && Cookie.get('loaded') !== 'true') {
      // console.log('loaded NOT true: ' + Cookie.get('loaded'));
      Cookie.set('loaded', 'true');
      document.querySelector('.content').classList.add('animated', 'fadeIn', 'delay-half-1s');
      document.querySelector('.hero').classList.add('animated', 'fadeIn', 'delay-half-1s');
      document.querySelector('.nav-main').classList.add('animated', 'fadeIn', 'delay-half-1s');

      setTimeout(function() {
        document.querySelector('.nav-main').classList.remove('animated', 'fadeIn', 'delay-half-1s');
      }, 2000);
    } else {
      // console.log('loaded true: ' + Cookie.get('loaded'));
      document.querySelector('.content').classList.add('animated', 'fadeIn', 'delay-half-1s');
      document.querySelector('.hero').classList.add('animated', 'fadeIn', 'delay-half-1s');
    }
  });
  
  // NAV MOBILE
  document.querySelector('.js-nav-mobile-button').addEventListener('click', function(e) {
    document.querySelector('body').classList.add('nav-mobile-open');
    e.preventDefault();
  });

  document.querySelector('.js-nav-mobile-close-button').addEventListener('click', function(e) {
    document.querySelector('body').classList.remove('nav-mobile-open');
    e.preventDefault();
  });

  // LIGHTBOX
  if (
    document.querySelector('.index-page') &&
    document.querySelector('body').classList.contains('index-page') ||
    document.querySelector('.services-page') &&
    document.querySelector('body').classList.contains('services-page')
  ) {

    document.querySelector('.scroll-down').addEventListener('click', function(e) {
      document.querySelector('.content').scrollIntoView({ behavior: 'smooth' });
      e.preventDefault();
    });

    document.querySelector('.scroll-up').addEventListener('click', function(e) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      e.preventDefault();
    });
  }

  // var controller = new ScrollMagic.Controller();
  // new ScrollMagic.Scene({
  //   duration: 600, // the scene should last for a scroll distance of 100px
  //   offset: 0 // start this scene after scrolling for 50px
  // })
  // .setPin('.hello') // pins the element for the the scene's duration
  // .addTo(controller);
})();