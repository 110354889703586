const contentful = require('contentful');
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import Tippy from 'tippy.js';

(function() {
  var resumeEntryContent;
  var heroEntryContent;
  var navigationEntryContent;
  var contactEntryContent;
  var projectsPageEntryContent;
  var servicesPageEntryContent;
  var client = contentful.createClient({
    space: 'pl1hf574fn1r',
    accessToken: 'XMBCwguDNFanWhFzhAQMFsPX81QCssOL2ymGvC4t6DE'
  });

  var forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  };

  function setNavigationContent(entry) {
    navigationEntryContent = entry.items[0].fields;
    // console.log(navigationEntryContent);
    document.querySelector('.js-nav-index').textContent = navigationEntryContent.link1Text;
    document.querySelector('.js-nav-services').textContent = navigationEntryContent.link2Text;
    document.querySelector('.js-nav-contact').textContent = navigationEntryContent.link3Text;
    // document.querySelector('.js-nav-resume').href = navigationEntryContent.resumeUrl.fields.file.url;
    // var resumeLinks = document.querySelectorAll('.nav-resume');
    // forEach(resumeLinks, function(index) {
    //   resumeLinks[index].href = resumeEntryContent.fields.file.url
    // });
  }

  var projectsContainer = document.querySelector('#projects');
  var servicesContainer = document.querySelector('#services');
  
  function setHeroContent(entry) {
    heroEntryContent = entry.items[0].fields;
    // console.log(heroEntryContent)
    document.querySelector('.homepageJobTitle').textContent = heroEntryContent.heroJobTitle;
    document.querySelector('.homepageJobLocation').textContent = heroEntryContent.heroJobLocation;
    document.querySelector('.homepageLogoLink').href = heroEntryContent.heroLogoLink;
    // document.querySelector('.homepageTitle').textContent = homepageEntryContent.homepageTitle;
    // document.querySelector('.homepageInfo').textContent = homepageEntryContent.homepageInfo;
  }
  
  const website_url = 'https://mydomain.com';
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        return `<a href=${node.data.uri} target="${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}" rel="${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}">${node.content[0].value}</a>`;
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        // console.log(node)
        return `<a href=${node.data.target.fields.file.url} target="_blank">${node.content[0].value}</a>`;
      },
      [BLOCKS.PARAGRAPH]: (node, next) => {
        return `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // console.log(node);
        var contentType = node.data.target.fields.file.contentType;
        if (contentType.includes('image')) {
          return `<img src="${node.data.target.fields.file.url}"/>`
        } else if (contentType.includes('video')) {
          return `<video controls loop playsinline preload="auto" src="${node.data.target.fields.file.url}"></video>`
        }
      }
    },
  }
  
  function setProjectsPageContent(entry) {
    projectsPageEntryContent = entry.items[0].fields;
    // console.log(projectsPageEntryContent)
    document.querySelector('.homepageTitle').textContent = projectsPageEntryContent.projectsTitle;
    document.querySelector('.homepageInfo').textContent = projectsPageEntryContent.projectsInfo;
  }

  function setProjectsContent(entries) {
    // log the title for all the entries that have it
    entries.items.forEach(function (entry) {
      // console.log(entry);
      var projectTitle = entry.fields.title;
      var projectDescription = documentToHtmlString(entry.fields.content, options).toString();
      // var projectDescription2 = entry.fields.content;
      // var newProjectDescription = projectDescription.replace('<a ', '<a target="_blank" ');

      // var newProjectDescription = projectDescription.replace(/<a /g, '<a target="_blank" ');
      var newProjectDescription = projectDescription;
      // console.log(projectDescription.replace('<a ', '<a target="_blank" '));
      // console.log(newProjectDescription);
      // console.log(documentToHtmlString(projectDescription2).replace('<a", "<a target="_blank"'));

      var projectContent = `
        <div
          class="about-heading tooltip-trigger"
          data-tooltip="--tooltip-${ projectTitle.replace(/ /g, '').replace(/[^a-zA-Z ]/g, "")}"
          data-aos="fade-up"
        >
          <h2>
            ${ projectTitle }
          </h2>
        </div>
        <div
          class="about-content"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          ${ newProjectDescription }
        </div>
      `;

      var projectImage = false;
      var projectImageDetails;
      var projectTooltip;
      var projectTooltipSize = '';
      if (entry.fields.tooltipImage) {
        projectImage = entry.fields.tooltipImage.fields.file.url;
        projectImageDetails = entry.fields.tooltipImage.fields.file.details.image;
        if (projectImageDetails.width < projectImageDetails.height) {
          projectTooltipSize = '--small';
        }
        projectTooltip = `
          <div class="--tooltip-${ projectTitle.replace(/ /g, '').replace(/[^a-zA-Z ]/g, "")} tooltip ${ projectTooltipSize }">
            <img src="${ projectImage }">
            <button class="nav-mobile-close-button js-nav-mobile-close-button"></button>
          </div>
        `;
      }

      // projectsContainer.appendChild(projectContent);
      projectsContainer.insertAdjacentHTML('beforeend', projectContent);
      if (projectImage) {
        // projectsContainer.appendChild(projectTooltip);
        projectsContainer.insertAdjacentHTML('beforeend', projectTooltip);
      }

      var tooltips = document.querySelectorAll('.tooltip-trigger');
      forEach(tooltips, function(index) {
        Tippy(tooltips[index], {
          allowHTML: true,
          content: document.querySelector('.'+tooltips[index].getAttribute('data-tooltip')),
          boundary: '#content-wrapper',
          arrow: false,
          placement: "top-start",
          offset: "30, 0",
          maxWidth: 530,
          trigger: "mouseenter focus",
          // appendTo: "#content",
          onMount: function() {
            setTimeout(function() {
              document.querySelector('body').classList.add('lightbox-open');
            }, 500);
          },
          onHide: function() {
            document.querySelector('body').classList.remove('lightbox-open');
          }
        })
      });
    });
  }


  
  function setServicesPageContent(entry) {
    servicesPageEntryContent = entry.items[0].fields;
    // console.log(projectsPageEntryContent)
    document.querySelector('.homepageTitle').textContent = servicesPageEntryContent.servicesTitle;
    document.querySelector('.homepageInfo').textContent = servicesPageEntryContent.servicesInfo;
  }

  function setServicesContent(entries) {
    // log the title for all the entries that have it
    entries.items.forEach(function (entry) {
      // console.log(entry);
      var serviceTitle = entry.fields.title;
      var serviceDescription = documentToHtmlString(entry.fields.content, options).toString();
      // console.log(entry.fields.content);
      // var projectDescription2 = entry.fields.content;
      // var newProjectDescription = projectDescription.replace('<a ', '<a target="_blank" ');

      // var newProjectDescription = projectDescription.replace(/<a /g, '<a target="_blank" ');
      var newServiceDescription = serviceDescription;
      // console.log(newServiceDescription);
      // console.log(newProjectDescription);
      // console.log(documentToHtmlString(projectDescription2).replace('<a", "<a target="_blank"'));

      var serviceContent = `
        <div
          class="services-heading tooltip-trigger"
          data-tooltip="--tooltip-${ serviceTitle.replace(/ /g, '').replace(/[^a-zA-Z ]/g, "")}"
          data-aos="fade-up"
        >
          <h2>
            ${ serviceTitle }
          </h2>
        </div>
        <div
          class="services-content"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          ${ newServiceDescription }
        </div>
      `;

      var serviceImage = false;
      var serviceImageDetails;
      var serviceTooltip;
      var serviceTooltipSize = '';
      if (entry.fields.tooltipImage) {
        serviceImage = entry.fields.tooltipImage.fields.file.url;
        serviceImageDetails = entry.fields.tooltipImage.fields.file.details.image;
        if (serviceImageDetails.width < serviceImageDetails.height) {
          serviceTooltipSize = '--small';
        }
        serviceTooltip = `
          <div class="--tooltip-${ serviceTitle.replace(/ /g, '').replace(/[^a-zA-Z ]/g, "")} tooltip ${ serviceTooltipSize }">
            <img src="${ serviceImage }">
            <button class="nav-mobile-close-button js-nav-mobile-close-button"></button>
          </div>
        `;
      }

      // servicesContainer.appendChild(projectContent);
      servicesContainer.insertAdjacentHTML('beforeend', serviceContent);
      if (serviceImage) {
        // servicesContainer.appendChild(projectTooltip);
        servicesContainer.insertAdjacentHTML('beforeend', serviceTooltip);
      }

      var tooltips = document.querySelectorAll('.tooltip-trigger');
      forEach(tooltips, function(index) {
        Tippy(tooltips[index], {
          allowHTML: true,
          content: document.querySelector('.'+tooltips[index].getAttribute('data-tooltip')),
          boundary: '#content-wrapper',
          arrow: false,
          placement: "top-start",
          offset: "30, 0",
          maxWidth: 530,
          trigger: "mouseenter focus",
          // appendTo: "#content",
          onMount: function() {
            setTimeout(function() {
              document.querySelector('body').classList.add('lightbox-open');
            }, 500);
          },
          onHide: function() {
            document.querySelector('body').classList.remove('lightbox-open');
          }
        })
      });
    });
  }

  function setContactContent(entry) {
    // console.log(entry);
    contactEntryContent = entry.items[0].fields;
    document.querySelector('.contactImage').src = contactEntryContent.contactImage.fields.file.url;
    document.querySelector('.contactTitle').textContent = contactEntryContent.contactTitle;
    const contactInfo = documentToHtmlString(entry.items[0].fields.contactInfo, options).toString();
    // console.log(contactInfo);
    // return documentToHtmlString(rawRichTextField);
    // var contactInfo = contactEntryContent.contactInfo.content[0].content[0].value + '<a href="' + contactEntryContent.contactInfo.content[0].content[1].data.uri + '">' + contactEntryContent.contactInfo.content[0].content[1].content[0].value + '</a>'
    document.querySelector('.contactInfo').innerHTML = contactInfo;
  }

  // Resume
  // client.getEntries({
  //   content_type: "resumeLink"
  // })
  // .then((entry) => setResumeContent(entry));

  client.getEntries({
    content_type: "navigation"
  })
  .then((entry) => setNavigationContent(entry));

  // Homepage
  if (
    document.querySelector('.index-page') &&
    document.querySelector('body').classList.contains('index-page')
  ) {
    client.getEntries({
      content_type: "hero"
    })
    .then((entry) => setHeroContent(entry));

    client.getEntries({
      content_type: "projectInfo"
    })
    .then((entries) => setProjectsContent(entries));

    client.getEntries({
      content_type: "projectsPage"
    })
    .then((entries) => setProjectsPageContent(entries));
  }

  // Services
  if (
    document.querySelector('.services-page') &&
    document.querySelector('body').classList.contains('services-page')
  ) {
    client.getEntries({
      content_type: "hero"
    })
    .then((entry) => setHeroContent(entry));

    client.getEntries({
      content_type: "service"
    })
    .then((entries) => setServicesContent(entries));

    client.getEntries({
      content_type: "servicespage"
    })
    .then((entries) => setServicesPageContent(entries));
  }

  // Contact
  if (
    document.querySelector('.contact-page') &&
    document.querySelector('body').classList.contains('contact-page')
  ) {
    client.getEntries({
      content_type: "contact"
    })
    .then((entry) => setContactContent(entry));
  }
})();